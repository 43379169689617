import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import BakeMarkLogo from '../../assets/BakeMarkLogo.png';
import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';
import msalConfig from '../../msalConfig';
import { UserAgentApplication } from 'msal';
import { LOGIN, SET_UAA_AUTH } from '../../store/actionTypes';
import qs from 'querystring'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: 'center'
  },
  bakeMarkImg: {
    width: '100%',
    marginTop: theme.spacing(3.5)
  },
  mainButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  appVersion: {
    position: 'fixed',
    bottom: 0,
    color: 'lightgray'
  }
}));

const mapStateToProps = (state) => {
  return {
    userAgentApplication: state.auth.userAgentApplication,
    user: state.auth.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthData: (auth) => {
      dispatch({
        type: LOGIN,
        auth
      });
    },
    setAuthKey: (userAgentApplication) => {
      dispatch({
        type: SET_UAA_AUTH,
        userAgentApplication
      });
    }
  }
}

function Login(props) {
  const classes = useStyles();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  let { from, event } = props.location.state || { from: { pathname: "/" } };
  const [open, setOpen] = useState(Boolean(event));
  const config = {
    auth: {
      clientId: msalConfig.appId,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    }
  }
  let userAgentApplication = props.userAgentApplication || new UserAgentApplication(config)
  
  useEffect(() => {
    if (redirectToReferrer === false) {
      setUser(userAgentApplication);
    }
  })
  
  const setUser = async (uAA) => {
    props.setAuthKey(userAgentApplication)
    let uAAUser = uAA.getAccount()
    
    const bmRegex = /(kellenschmidt31@outlook.com|@bakemark.com)$/i
    if (uAAUser) {
      const { name, userName } = uAAUser
      const email = userName.toLowerCase()
  
      try {
        const results = await axios.get('/v1/user?' + qs.stringify({
          email
        }))
        var { userId, isManager, isCanada } = results.data
      } catch (err) {
        console.warn("Could not get user", err)
        return
      }

      props.setAuthData({
        isAuthenticated: true,
        isBakeMarkAccount: bmRegex.test(email),
        isManager: Boolean(isManager),
        isCanada: Boolean(isCanada),
        user: { name, email, userId }
      })
      setRedirectToReferrer(true)
    }
  }

  async function login() {
    let loginRequest = {
      scopes: msalConfig.scopes,
      prompt: "select_account",
    }

    try {
      await userAgentApplication.loginPopup(loginRequest);
      setUser(userAgentApplication)
    }
    catch (err) {
      props.setAuthKey(userAgentApplication);
      var errParts = err.split('|');
      props.setAuthData({
        isAuthenticated: false,
        user: {},
        error: { message: errParts[1], debug: errParts[0] }
      });
    }
  }

  if (redirectToReferrer) {
    return <Redirect to={{ ...from, state: { event: "LOGIN_SUCCESSFUL", user: props.user.name} }} />;
  }

  return (
    <Grid item xs={12} sm={11} md={10} lg={9} xl={6}>
      <Grid container justify="center">
        <Grid item xs={10} sm={9} lg={8}>
          <img src={BakeMarkLogo} alt="BakeMark logo" className={classes.bakeMarkImg} />
        </Grid>
      </Grid>
      <Grid container className={classes.centerAlign} justify="center">
        <Grid item xs={12} sm={7} lg={6}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            onClick={login}
            className={classes.mainButton}
            >
            Login
          </Button>
          <small>Use your BakeMark Office 365 account</small>
        </Grid>
      </Grid>
      <Grid container className={classes.centerAlign} justify="center">
        <Grid item xs={12}>
          <small className={classes.appVersion}>Version: {(process.env.REACT_APP_VERSION || "0")}</small>
        </Grid>
      </Grid>
      <ColoredSnackbar open={open} onClose={() => setOpen(false)} event={event} />
    </Grid>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
