import React from 'react';
import { connect, useSelector } from 'react-redux';
import CategoryInput from '../InquirySelect/CategoryInput';
import SubcategoryInput from '../InquirySelect/SubcategoryInput';
import CompetitorInput from '../InquirySelect/CompetitorInput';
import ProductInput from '../InquirySelect/ProductInput';
import { SET_MATCH_INQUIRY_CATEGORY, SET_MATCH_INQUIRY_SUBCATEGORY, SET_MATCH_INQUIRY_FLOUR_DETAILS, SET_MATCH_INQUIRY_COMPETITOR, SET_MATCH_INQUIRY_PRODUCT } from '../../store/actionTypes';
import FlourDetails from '../FlourDetails/FlourDetails';
import BrandInput from '../InquirySelect/BrandInput';

const mapDispatchToProps = (dispatch) => {
  return {
    setCategory: (category, isOther) => {
      dispatch({
        type: SET_MATCH_INQUIRY_CATEGORY,
        category,
        isOther
      })
    },
    setSubcategory: (subcategory, isOther) => {
      dispatch({
        type: SET_MATCH_INQUIRY_SUBCATEGORY,
        subcategory,
        isOther
      })
    },
    setFlourDetails: (flourDetails) => {
      dispatch({
        type: SET_MATCH_INQUIRY_FLOUR_DETAILS,
        flourDetails
      })
    },
    setCompetitor: (competitor, isOther) => {
      dispatch({
        type: SET_MATCH_INQUIRY_COMPETITOR,
        competitor,
        isOther
      })
    },
    setProduct: (product, isOther) => {
      dispatch({
        type: SET_MATCH_INQUIRY_PRODUCT,
        product,
        isOther
      })
    },
  }
}

function CoreInquiryForm(props) {
  const state = useSelector(state => ({
    category: state.matchInquiryForm.category,
    categoryOther: state.matchInquiryForm.categoryOther,
    subcategory: state.matchInquiryForm.subcategory,
    subcategoryOther: state.matchInquiryForm.subcategoryOther,
    hideSubcategorySelect: state.matchInquiryForm.category.value === -2,
    showFlourDetailsInput: state.matchInquiryForm.category.value === 23,
    flourDetails: state.matchInquiryForm.flourDetails,
    competitor: state.matchInquiryForm.competitor,
    competitorOther: state.matchInquiryForm.competitorOther,
    product: state.matchInquiryForm.product,
    productOther: state.matchInquiryForm.productOther,
    hideProductSelect: state.matchInquiryForm.category.value === -2 || state.matchInquiryForm.subcategory.value === -2,
  }))
  
  return (
    <>
      <CategoryInput category={state.category.label} categoryOther={state.categoryOther} setCategory={props.setCategory} showHint={props.showHints}/>
      <SubcategoryInput category={state.category} subcategory={state.subcategory.label} subcategoryOther={state.subcategoryOther} setSubcategory={props.setSubcategory} hideSelect={state.hideSubcategorySelect} showHint={props.showHints}/>
      {state.showFlourDetailsInput && <FlourDetails data={state.flourDetails} setData={props.setFlourDetails} />}
      {props.isBakemark ? ( // Reusing competitor field for BakeMark brand
        <BrandInput brand={state.competitor.label} brandOther={state.competitorOther} setBrand={props.setCompetitor} showHint={props.showHints}/>
      ) : (
        <CompetitorInput competitor={state.competitor.label} competitorOther={state.competitorOther} setCompetitor={props.setCompetitor} showHint={props.showHints}/>
      )}
      <ProductInput category={state.category.value} subcategory={state.subcategory} competitor={state.competitor} product={state.product.label} productOther={state.productOther} flourDetails={state.flourDetails} setProduct={props.setProduct} hideSelect={state.hideProductSelect || props.hideProductSelect} showHint={props.showHints} hideDialog={props.showHints}/>
    </>
  );
}

export default connect(null, mapDispatchToProps)(CoreInquiryForm);
