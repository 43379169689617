import moment from 'moment';

export const createInquirySubmission = (inquiryForm, authUser, userLocation) => {
  const { inquiryId, category, subcategory, flourDetailsId, flourDetails, competitor, product, categoryOther, subcategoryOther, competitorOther, productOther, pictures, price, date, userId } = inquiryForm;

  let categoryData
  if (category.value === -1) {
    categoryData = {
      categoryId: null,
      categoryText: null
    }
  } else if (category.value !== -2) {
    categoryData = {
      categoryId: category.value,
      categoryText: null
    }
  } else {
    categoryData = {
      categoryId: null,
      categoryText: categoryOther
    }
  }

  let subcategoryData
  if (subcategory.value === -1) {
    subcategoryData = {
      subcategoryId: null,
      subcategoryText: null
    }
  } else if (subcategory.value !== -2 && category.value !== -2) {
    subcategoryData = {
      subcategoryId: subcategory.value,
      subcategoryText: null
    }
  } else {
    subcategoryData = {
      subcategoryId: null,
      subcategoryText: subcategoryOther
    }
  }

  let competitorData
  if (competitor.value === -1) {
    competitorData = {
      competitorId: null,
      competitorText: null
    }
  } else if (competitor.value !== -2) {
    competitorData = {
      competitorId: competitor.value,
      competitorText: null
    }
  } else {
    competitorData = {
      competitorId: null,
      competitorText: competitorOther
    }
  }

  let productData
  if (product.value === -1 || (product.value.name === "" && product.value.code === "")) {
    productData = {
      productId: null,
      productNameText: null,
      productCodeText: null,
    }
  } else if (product.value !== -2 && subcategory.value !== -2 && category.value !== -2) {
    productData = {
      productId: product.value,
      productNameText: null,
      productCodeText: null
    }
  } else {
    productData = {
      productId: null,
      productNameText: productOther.name,
      productCodeText: productOther.code,
    }
  }

  const pictureData = pictures.map(value => value.s3Key)

  return {
    inquiryId,
    ...categoryData,
    ...subcategoryData,
    flourDetailsId,
    flourDetails,
    ...competitorData,
    ...productData,
    pictures: pictureData,
    ...userLocation,
    price: price ? price : null,
    date: date || moment.utc(),
    userId: userId || (authUser && authUser.userId) 
  }
}

export const isFormInvalid = (inquiryForm) => {
  const { category, subcategory, flourDetails, competitor, product, competitorOther, productOther } = inquiryForm;

  if (category.value === -1) {
    return true
  }
  if (category.value === 23 && (flourDetails.protein === "" || flourDetails.ash === "")) {
    return true
  }
  if (subcategory.value === -1 && category.value !== -2) {
    return true
  }
  if (competitor.value === -1 || (competitor.value === -2 && competitorOther === "")) {
    return true
  }
  if (product.value === -1 || (product.value === -2 && productOther.name === "")) {
    return true
  }

  return false
}
