import axios from 'axios';
import qs from 'querystring';
import {
  createInquirySubmission as startInquirySubmission,
  isFormInvalid as isCoreFormInvalid,
} from './sharedInquiryFunctions'

export const createInquirySubmission = (inquiryForm, authUser, userLocation) => {
  const partialInquiryData = startInquirySubmission(inquiryForm, authUser, userLocation)
  const { customer, customerOther, netWeight, unitOfMeasure } = inquiryForm;

  let customerData
  if (customer.value !== -2) {
    customerData = {
      customerId: customer.value,
      customerNameText: null,
      customerCodeText: null
    }
  } else {
    customerData = {
      customerId: null,
      customerNameText: customerOther.name,
      customerCodeText: customerOther.code,
    }
  }

  return {
    ...partialInquiryData,
    ...customerData,
    netWeight: netWeight ? netWeight : null,
    unitOfMeasure: unitOfMeasure ? unitOfMeasure : null,
  }
}

export function writeInquiryToLS(inquiryData, userLocation) {
  let newArray = []
  let dataToStore = {...inquiryData, userLocation}

  if (localStorage.pendingInquiries) {
    const oldArray = JSON.parse(localStorage.pendingInquiries)
    newArray = [dataToStore, ...oldArray];
  } else {
    newArray = [dataToStore];
  }

  localStorage.pendingInquiries = JSON.stringify(newArray);
}

export async function submitInquiry(inquiryForm, authUser, userLocation, setInquiryId) {
  let newPath = "/"
  let newState = {}
  let connectionError = false
  const submissionData = createInquirySubmission(inquiryForm, authUser, userLocation)
  
  try {
    var results = await axios.get('/v1/bakemark-products?' + qs.stringify({
      competitiveProductId: submissionData.productId || -1
    }))
    var createInquiryResults = await axios.post('/v1/inquiry', {
      inquiryData: submissionData
    })
    setInquiryId(createInquiryResults.data.insertId)
  } catch (err) {
    console.warn("Could not submit inquiry,", err)
    connectionError = err.toString().includes("Network")
    writeInquiryToLS(inquiryForm, userLocation)
  }

  if (connectionError) {
    newPath = "/"
    newState = { event: "CONNECTION_ERROR" }
  } else if (results.data['productFound']) {
    newPath = "/inquiry/match/results"
    newState = { results: results.data['array'] }
  } else {
    newPath = "/inquiry/match/more-info"
    newState = {}
  }

  return {
    newPath,
    newState
  }
}

export function isMainFormInvalid(inquiryForm) {
  const { customer, customerOther } = inquiryForm;

  if (isCoreFormInvalid(inquiryForm)) {
    return true
  }
  if ((customer.label === "" || customer.value < 0) && customerOther.name === "") {
    return true
  }
  
  return false
}

export function isAddlFormInvalid(inquiryForm) {
  const { netWeight, unitOfMeasure, pictures } = inquiryForm;

  if (netWeight === "" || unitOfMeasure === "") {
    return true
  }
  if (pictures.length === 0) {
    return true
  }
  return false
}

export const formatInquiryForDisplay = (input) => {
  const product = (input.productOther) ? {
    label: input.productOther.name,
    code: input.productOther.code,
  } : {
    label: input.product.label,
    code: input.product.code,
    manufacturedInCanada: input.product.manufacturedInCanada
  }

  const competitor = (input.competitorOther) ? {
    label: input.competitorOther,
  } : {
    label: input.competitor.label,
  }

  return {
    product,
    competitor,
    pictures: input.pictures,
  }
}

export const getInquiryResults = async (competitiveProductId) => {
  var results = await axios.get('/v1/bakemark-products?' + qs.stringify({
    competitiveProductId
  }))

  return results.data['array']
}
